/*** IMPORTS FROM imports-loader ***/
var define = false;

$(document).ready(function(){
	
	$('.slider-expertises-footer').slick({
		infinite: true,
		dots: false,
		autoplay: true,
		autoplaySpeed: 3000,
		speed: 500,
		prevArrow: $('.slider-prev'),
		nextArrow: $('.slider-next'),
		
	});
	
	$('.slider_mot').slick({
		infinite: true,
		prevArrow: false,
    	nextArrow: false,
		dots: false,
		fade: true,
		autoplay: true,
		autoplaySpeed: 800,
		speed: 500,
		swipe:false,		
	});
	
	$('.slider_histoire').slick({
		infinite: true,
		prevArrow: false,
    	nextArrow: false,
		dots: true,
	});
	$('.histoire .menu li a').on('click',function(){
		$('.slider_histoire').slick('slickGoTo', $(this).data('page'));
	});
	
	$('.slider-visuels').slick({
		infinite: true,
		prevArrow: false,
    	nextArrow: false,
		autoplay : true,
		dots: true,
		autoplaySpeed: 3000,
		speed: 1000,
	});
	
	$('.slider-simple').slick({
		infinite: true,
		prevArrow: false,
    	nextArrow: false,
		autoplay : true,
		autoplaySpeed: 3000,
		speed: 1000,
	});
	
	$('.slider-temoignages').slick({
		infinite: true,
		prevArrow: false,
    	nextArrow: false,
		dots: true,
		autoplay : true,
		autoplaySpeed: 3000,
	});
	
	$('.slider-realisations').slick({
		infinite: false,
		prevArrow: false,
    	nextArrow: false,
		dots: true,
	});
	
	$('.slider-multiple').slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		prevArrow: $('.slider-prev'),
		nextArrow: $('.slider-next'),
		responsive: [
			{
			  breakpoint: 768,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			  }
			},
		  ]
		
	});
	
	$('.slider-client').slick({
		infinite: true,
		slidesToShow: 8,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
		swipe:false,
		responsive: [
			{
			  breakpoint: 990,
			  settings: {
				slidesToShow: 4,
				slidesToScroll: 4
			  }
			},
			{
			  breakpoint: 768,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 3
			  }
			},
		  ]
	});
	
	$('.slider-offres').slick({
		fade: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		swipe:false,
		cssEase: 'linear'
	});
	
	$('.slider-dictionnaire').slick({
		prevArrow: false,
    	nextArrow: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		swipe:false,
	});
	
	$('.slider-expertise').slick({
		infinite: true,
		slidesToShow: 2,
		slidesToScroll: 2,
		/*autoplay: true,*/
		autoplaySpeed: 2000,
		prevArrow: false,
    	nextArrow: false,
		dots: true,
		responsive: [
			{
			  breakpoint: 768,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			},
		  ]
	});
	
	$('.slider-pret').slick({
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 3,
		swipe:false,
		vertical:true,
		verticalSwiping:true,
	});
	$('.slider-pret img').on('click', function(){
		$("#pret-"+$(this).data("id")+" .visuel img").attr("src",$(this).data("src"));
	});
	
	
	/*$(".partage a").on("click",function(){
		 navigator.clipboard.writeText('https://www.jbdgroupe.com/');
		alert("L'url du site a été copiée");
		e.preventDefault();
	});*/
	
});

