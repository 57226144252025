/*** IMPORTS FROM imports-loader ***/
var define = false;

"use strict";

//import bootstrap from 'bootstrap/dist/js/bootstrap.min';
window.bootstrap = require('bootstrap');

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import main from '@src/js/main.js';
